import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center right horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/ae447cdc37bf5aa197abb9c9df318360/aa440/Morne_An_immense_prismatic_portal_circular_gate_structure_atop__2a93c164-c671-47fc-aa0b-9a8f76b3c661.png",
            "srcSet": ["/static/ae447cdc37bf5aa197abb9c9df318360/5ff7e/Morne_An_immense_prismatic_portal_circular_gate_structure_atop__2a93c164-c671-47fc-aa0b-9a8f76b3c661.png 375w", "/static/ae447cdc37bf5aa197abb9c9df318360/1d69c/Morne_An_immense_prismatic_portal_circular_gate_structure_atop__2a93c164-c671-47fc-aa0b-9a8f76b3c661.png 750w", "/static/ae447cdc37bf5aa197abb9c9df318360/aa440/Morne_An_immense_prismatic_portal_circular_gate_structure_atop__2a93c164-c671-47fc-aa0b-9a8f76b3c661.png 1500w", "/static/ae447cdc37bf5aa197abb9c9df318360/71c1d/Morne_An_immense_prismatic_portal_circular_gate_structure_atop__2a93c164-c671-47fc-aa0b-9a8f76b3c661.png 1536w"],
            "width": "500px",
            "className": "center right horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`A Prismagate in Savaar`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p><strong parentName="p">{`Prismagates`}</strong>{` are massive `}<a parentName="p" {...{
        "href": "/Junction",
        "title": "Junction"
      }}>{`Junctions`}</a>{` maintained by `}<a parentName="p" {...{
        "href": "/Acronist#vastcaller",
        "title": "Acronist"
      }}>{`Vastcallers`}</a>{` meant to transport Aerostats between cities. These require significant coordination between governments, as Junctions this large must be meticulously preserved to remain functional.`}</p>
    <h2>{`Naming`}</h2>
    <p>{`The name Prismagate comes from the mirror-like appearance along the interior of the archway, reflecting different wavelengths of light in slightly different ways, giving a prismatic effect.`}</p>
    <h2>{`Cost of Maintaining`}</h2>
    <p>{`These gateways are difficult and expensive to both build and maintain. It requires a large number of `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` to be available and willing to work to build the `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Conviction`}</a>{`, cooperation between the two nations (if the gates are to be linking across national borders), and skilled engineers and laborers to construct the gate with exacting precision in multiple locations as well as maintain the structural integrity of the gate overtime to ensure the Junction remains stable.`}</p>
    <h2>{`Notable Prismagates`}</h2>
    <ul>
      <li parentName="ul">{`The Prismagate in `}<a parentName="li" {...{
          "href": "/Meripol",
          "title": "Meripol"
        }}>{`Meripol`}</a>{` is maintained by the `}<a parentName="li" {...{
          "href": "/Prisma%20Travel%20Corporation",
          "title": "Prisma Travel Corporation"
        }}>{`Prisma Travel Corporation`}</a>{`.`}</li>
    </ul>
    <h2>{`Creation of Prismagates`}</h2>
    <p>{`The creation of Prismagates requires the following in two stages:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Creation of the physical gates in two separate locations. The gates must be as identical as possible—usually the addition of runes along the structure in the same place helps with this.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Multiple Acronists at either gate forming the `}<a parentName="p" {...{
            "href": "/Conviction",
            "title": "Conviction"
          }}>{`Conviction`}</a>{`. The Acronists must work simultaneously at both ends of the junction, building up a Conviction strong enough to form a `}<a parentName="p" {...{
            "href": "/Fixed%20Truth",
            "title": "Fixed Truth"
          }}>{`Fixed Truth`}</a>{`. The number of Acronists required for this varies due to a number of factors, but is generally between 10 and 20 on either side.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      